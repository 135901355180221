import * as Actions from "./actions";
import { CreateOrderState, createOrderInitialState } from "./state";

export const createOrderReducer = (
  state: CreateOrderState = createOrderInitialState,
  action: Actions.CreateOrderActions
): CreateOrderState => {
  switch (action.type) {
    case Actions.CREATE_ORDER_TYPE:
      return { ...state, isLoading: true };
    case Actions.CREATE_ORDER_SUCCESS_TYPE:
      return { ...state, isLoading: false };
    case Actions.CREATE_ORDER_FAILURE_TYPE:
      return { ...state, isLoading: false, error: action.payload };
    default:
      return state;
  }
};
