import * as Actions from './actions';
import { GetOrdersState, getOrdersInitialState } from './state';

export const getOrdersReducer = (
    state: GetOrdersState = getOrdersInitialState,
    action: Actions.GetOrdersActions
): GetOrdersState => {
    switch (action.type) {
        case Actions.GET_ORDERS_TYPE:
            return { ...state, isLoading: true };
        case Actions.GET_ORDERS_SUCCESS_TYPE:
            return { ...state, isLoading: false, orders: action.payload };
        case Actions.GET_ORDERS_FAILURE_TYPE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};
