import { OrderResponse } from "../order-response.interface";

export interface GetOrdersState {
    isLoading: boolean;
    error: any;
    orders: OrderResponse[]
}

export const getOrdersInitialState: GetOrdersState = {
    isLoading: false,
    error: null,
    orders:[]
};
