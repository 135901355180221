import camelcaseKeysDeep from 'camelcase-keys-deep';
import decamelizeKeysDeep from 'decamelize-keys-deep';

function FetchClient() {
    let authToken: string | null = null;

    const getHeaders = () => {
        const headers: { [header: string]: string } = {
            'Content-Type': 'application/json'
        };

        if (authToken) {
            headers.Authorization = `Bearer ${authToken}`;
        }
        return headers;
    };

    const getJSONResponseBody = async <T>(response: Response, caseConvert: boolean): Promise<T | null> => {
        if (!response.ok) {
            throw new Error(String(response.status));
        }

        if (response.status === 204) {
            return null;
        }

        const result = await response.json();

        return camelcaseKeysDeep(result)
    };

    return {
        authToken,
        buildQuery(params?: { [key: string]: string | number | boolean | undefined }) {
            return params ? '?' + new URLSearchParams(decamelizeKeysDeep(params)).toString() : '';
        },
        setAuthToken(value: string) {
            authToken = value;
        },
        async getData<T = unknown>(url: string, caseConvert = false): Promise<T | null> {
            const response = await fetch(url, {
                headers: getHeaders()
            });

            return getJSONResponseBody<T>(response, caseConvert);
        },
        async putData<T = unknown>(url: string, payload: any, caseConvert = false): Promise<T | null> {
            const response = await fetch(url, {
                method: 'PUT',
                headers: getHeaders(),
                body: JSON.stringify(caseConvert ? decamelizeKeysDeep(payload) : payload)
            });

            return getJSONResponseBody<T>(response, caseConvert);
        },
        async postData<T = unknown>(url: string, payload: any, caseConvert = false): Promise<T | null> {
            const response = await fetch(url, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(caseConvert ? decamelizeKeysDeep(payload) : payload)
            });

            return getJSONResponseBody<T>(response, caseConvert);
        },
        async postMultipartData<T = unknown>(path: string, file: File, extraFields?: object, caseConvert = false): Promise<T | null> {
            const formData = new FormData();
            formData.append('file', file);

            if (extraFields) {
                Object.values(extraFields).forEach(([key, value]) => formData.append(key, value));
            }

            const response = await fetch(path, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authToken}`
                },
                body: formData
            });

            return getJSONResponseBody<T>(response, caseConvert);
        },
        async delete<T = unknown>(url: string, caseConvert = false): Promise<T | null> {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: getHeaders()
            });

            return getJSONResponseBody<T>(response, caseConvert);
        }
    };
}

export const fetchClient = FetchClient();
