import { createAction } from "@goodfood/utils";
import { Order } from "../order.interface";
import { OrderResponse } from "../order-response.interface";

export type CreateOrderActions =
  | CreateOrderAction
  | CreateOrderSuccessAction
  | CreateOrderFailureAction;

export const CREATE_ORDER_TYPE = "[GOOD FOOD SHOP] Create Order";
export type CreateOrderAction = ReturnType<typeof createOrder>;
export const createOrder = (order: Order) =>
  createAction(CREATE_ORDER_TYPE, order);

export const CREATE_ORDER_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Create Order success";
export type CreateOrderSuccessAction = ReturnType<typeof createOrderSuccess>;
export const createOrderSuccess = (order: OrderResponse) =>
  createAction(CREATE_ORDER_SUCCESS_TYPE, order);

export const CREATE_ORDER_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Create Order failure";
export type CreateOrderFailureAction = ReturnType<typeof createOrderFailure>;
export const createOrderFailure = (error: any) =>
  createAction(CREATE_ORDER_FAILURE_TYPE, error);
