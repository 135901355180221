import { fetchClient } from "@goodfood/lib";
import { getEndpoint } from "@goodfood/utils";
import { OrderResponse } from "@mealhaus/orders";

async function getCustomerLatestSubscription(): Promise<OrderResponse[]> {
  return (await fetchClient.getData<OrderResponse[]>(getEndpoint(`/customer/latest-meal-plan-subscription`)))!;
}

export const customerService = {
  getCustomerLatestSubscription
};
