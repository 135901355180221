import { createAction } from "@goodfood/utils";
import { OrderResponse } from "../order-response.interface";

export type GetOrderActions =
  | GetOrderAction
  | GetOrderSuccessAction
  | GetOrderFailureAction;

export const GET_ORDER_TYPE = "[GOOD FOOD SHOP] Get Order";
export type GetOrderAction = ReturnType<typeof getOrder>;
export const getOrder = (orderId: string) =>
  createAction(GET_ORDER_TYPE, { orderId });

export const GET_ORDER_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Get Order success";
export type GetOrderSuccessAction = ReturnType<typeof getOrderSuccess>;
export const getOrderSuccess = (order: OrderResponse) =>
  createAction(GET_ORDER_SUCCESS_TYPE, order);

export const GET_ORDER_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Get Order failure";
export type GetOrderFailureAction = ReturnType<typeof getOrderFailure>;
export const getOrderFailure = (error: any) =>
  createAction(GET_ORDER_FAILURE_TYPE, error);
