import React from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { subscriptionRoutes, routes } from '@mealhaus/navigation';
import { useCheckMobile } from '@mealhaus/hooks';
import classNames from 'classnames';

export const Stepper = () => {
    const { pathname, query, asPath, push } = useRouter();

    const isMobile = useCheckMobile(768)

    const activeRouteIndex = subscriptionRoutes.indexOf(pathname)

    const getRouteCircleClasses = (route, routeIndex) => {
        if (route === pathname) {
            return "bg-primary border-primary text-white"
        }
        if (activeRouteIndex > routeIndex) {
            return "bg-highlight border-highlight text-white"
        }
        return "border text-muted"
    }

    const getRouteLabelClasses = (route, routeIndex) => {
        if (route === pathname) {
            return "text-primary font-weight-500"
        }
        if (activeRouteIndex > routeIndex) {
            return "text-highlight font-weight-light"
        }
        return "text-muted font-weight-light"
    }

    const onClick=(pathname,routeIndex)=>{
        if (routeIndex >= activeRouteIndex ) {
            return
        }
        push({
            query:{...query},
            pathname
        })
    }

    const getRouteLabel = (route) => {
        switch (route.split('/')[1]) {
            case routes.ADDRESS:
                return "Delivery";
            case routes.SELECT_PLAN:
                return "Select plan";
            case routes.PAYMENT:
                return "Payment";
            case routes.MENU:
                return "Select recipes";
        }
    }

    return (
        <>
            <ul className="p-0 justify-content-center d-flex mb-0">
                {
                    subscriptionRoutes.map((route, idx) => {
                        return (
                            <li className={classNames("active d-flex align-items-center justify-content-center justify-content-md-between")} key={idx}>
                                <span 
                                className={classNames("align-items-center circle d-flex justify-content-center rounded-circle text-center text-xsmall ml-2",{"cursor-pointer": activeRouteIndex >= idx }, getRouteCircleClasses(route, idx))}
                                onClick={()=>onClick(route,idx)}
                                >{idx + 1}</span>
                                <span 
                                className={classNames("text-small mx-2 text-primary text-center", getRouteLabelClasses(route, idx), { "d-none": isMobile, "cursor-pointer": activeRouteIndex >= idx })}
                                onClick={()=>onClick(route,idx)}
                                >{getRouteLabel(route)}</span>
                                {idx !== subscriptionRoutes.length - 1 && <div className="dash border-top" />}
                            </li>
                        )
                    })
                }
            </ul>
            <style jsx>{`
.circle{
    width: 1.5rem;
    height:1.5rem;
}
.dash{
    width: 2rem
}
      `}</style>
        </>
    )
}
