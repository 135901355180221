import * as GetCustomerLatestSubscriptionActions from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { customerService } from '../customer.service';
import { CreateMealPlanSubscriptionSubscriptionDto } from '@mealhaus/meal-plan-subscription';
import { CreateOrderActions } from '@mealhaus/orders';

export function* getCustomerLatestSubscriptionSaga({ payload }: GetCustomerLatestSubscriptionActions.GetCustomerLatestSubscriptionAction) {
    try {
        const customerLatestSubscription: CreateMealPlanSubscriptionSubscriptionDto[] = yield call(customerService.getCustomerLatestSubscription);
        yield put(GetCustomerLatestSubscriptionActions.getCustomerLatestSubscriptionSuccess(customerLatestSubscription));
    } catch (error) {
        yield put(GetCustomerLatestSubscriptionActions.getCustomerLatestSubscriptionFailure(error));
    }
}

export const GetCustomerLatestSubscriptionSagas = [takeLatest([GetCustomerLatestSubscriptionActions.GET_CUSTOMER_LATEST_SUBSCRIPTION_TYPE,CreateOrderActions.CREATE_ORDER_SUCCESS_TYPE], getCustomerLatestSubscriptionSaga)];
