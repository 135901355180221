import * as CreateOrderActions from "./actions";
import { takeLatest, put, call } from "redux-saga/effects";
import { orderService } from "../order.service";
import Router from "next/router";

export function* createOrderSaga({
  payload,
}: CreateOrderActions.CreateOrderAction) {
  try {
    const order = yield call(orderService.createOrder, payload);
    yield put(CreateOrderActions.createOrderSuccess(order));
  } catch (error) {
    yield put(CreateOrderActions.createOrderFailure(error));
  }
}

export const CreateOrderSagas = [
  takeLatest(CreateOrderActions.CREATE_ORDER_TYPE, createOrderSaga),

];
