import { createAction } from "@goodfood/utils";
import { OrderResponse } from "../order-response.interface";


export type GetOrdersActions = GetOrdersAction | GetOrdersSuccessAction | GetOrdersFailureAction;

export const GET_ORDERS_TYPE = '[GOOD FOOD SHOP] Get orders';
export type GetOrdersAction = ReturnType<typeof getOrders>;
export const getOrders = () => createAction(GET_ORDERS_TYPE);

export const GET_ORDERS_SUCCESS_TYPE = '[GOOD FOOD SHOP] Get orders success';
export type GetOrdersSuccessAction = ReturnType<typeof getOrdersSuccess>;
export const getOrdersSuccess = (orders: OrderResponse[]) => createAction(GET_ORDERS_SUCCESS_TYPE, orders);

export const GET_ORDERS_FAILURE_TYPE = '[GOOD FOOD SHOP] Get orders failure';
export type GetOrdersFailureAction = ReturnType<typeof getOrdersFailure>;
export const getOrdersFailure = (error: any) => createAction(GET_ORDERS_FAILURE_TYPE, error);
