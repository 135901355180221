import { setTranslations, setLocale, addLocale } from 'react-i18nify';
import en from 'date-fns/locale/en-GB';

export function initI18n() {
    const locale = 'en';

    setTranslations({
        en: require('./translations/en.json')
    });

    addLocale('en', en);
    setLocale(locale);
}
