
import { Address } from "@mealhaus/address";
import { Recipe } from "src/recipes/recipe.interface";

export enum DeliveryStatus {
  PENDING = "PENDING",
  DELIVERED = "DELIVERED",
  CANCELLED = "CANCELLED"
}

export interface OrderResponse {
  readonly id: string;
  readonly isCompleted: boolean;
  readonly mealPlanSubscriptionId: boolean;
  readonly address: Address;
  readonly recipe: Recipe;
  readonly totalAmount: number;
  readonly phoneNumber: string;
  readonly deliveryDate: string;
  readonly deliveryStatus: DeliveryStatus;
}
