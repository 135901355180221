import * as React from "react";
import classNames from "classnames"
import { BsX } from "react-icons/bs"

interface Props {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode
}

export const MobileModal = ({ isOpen, onClose, children }: Props) => {
    return (
        <div className={classNames("overlay h-0 w-100 bg-white position-fixed overflow-hidden", { 'h-100': isOpen, 'd-none': !isOpen })}>
            <div className="d-flex justify-content-end py-4 px-2"><button className="btn close-btn d-flex text-primary" onClick={onClose}><BsX /></button></div>
            <div className="overlay-content position-relative">
                {children}
            </div>
            <style jsx>
                {`
                .overlay {
                    z-index: 1;
                    top: 0;
                    left: 0;
                    transition: 0.5s;
                }
                .close-btn{
                    font-size: 2.5rem
                }
        `}
            </style>
        </div>
    )
}
