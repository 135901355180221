import { CreateMealPlanSubscriptionSubscriptionDto } from "@mealhaus/meal-plan-subscription";

export interface GetCustomerLatestSubscriptionState {
    isLoading: boolean;
    isLoaded: boolean;
    error: any;
    subscription: CreateMealPlanSubscriptionSubscriptionDto[]
}

export const getCustomerLatestSubscriptionInitialState: GetCustomerLatestSubscriptionState = {
    isLoading: false,
    isLoaded: false,
    error: null,
    subscription: []
};

