import * as GetOrderActions from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { orderService } from '../order.service';
import { OrderResponse } from "../order-response.interface";

export function* getOrderSaga({ payload }: GetOrderActions.GetOrderAction) {
    try {
        const order: OrderResponse = yield call(orderService.getOrder, payload.orderId);
        yield put(GetOrderActions.getOrderSuccess(order));
    } catch (error) {
        yield put(GetOrderActions.getOrderFailure(error));
    }
}

export const GetOrderSagas = [takeLatest(GetOrderActions.GET_ORDER_TYPE, getOrderSaga)];
