import { useRouter } from 'next/router'
import React, { Fragment, useState, useEffect } from 'react';
import { useAuth } from 'use-auth0-hooks';
import { BsList, BsCardList } from "react-icons/bs";
import { translate } from 'react-i18nify';
import Link from 'next/link'
import { MobileModal } from '@mealhaus/components/MobileModal';
import { GetCustomerLatestSubscriptionState, GetCustomerLatestSubscriptionActions } from '@mealhaus/customer';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalAppState } from '@goodfood/store';
import { getconfig } from '@goodfood/utils';
import { fetchClient } from "@goodfood/lib";
import { Stepper } from '@mealhaus/components/Stepper';
import classNames from 'classnames';
import { useCheckMobile } from '@mealhaus/hooks';

const config = getconfig()
interface Props {

}

export enum routes {
    RECIPES = "recipes",
    ORDERS = "orders",
    MENU = "menu",
    SELECT_PLAN = "select-meal-plan",
    ADDRESS= "address",
    PAYMENT="payment",
    OTP="/one-time-purchase",
    OTP_ADDRESS="/one-time-purchase/address",
    OTP_PAYMENT="/one-time-purchase/payment",
    OTP_SUCCESS="/one-time-purchase/success"
}

export const subscriptionRoutes = [routes.SELECT_PLAN,routes.ADDRESS,routes.PAYMENT,routes.MENU].map(route=>(`/${route}`))

export const NavBar = ({ }: Props) => {
    const { pathname, query, asPath,push } = useRouter();
    const dispatch = useDispatch();
    
    const { accessToken } = useAuth({
        audience: config.audience,
        scope: config.scope
      });
      fetchClient.setAuthToken(accessToken)
    
    useEffect(() => {
        dispatch(GetCustomerLatestSubscriptionActions.getCustomerLatestSubscription());
    }, [accessToken])
    
    const { isAuthenticated, isLoading, login, logout } = useAuth();
    let handleAuth, authLabel

    const [openMobileMenu, setOpenMobileMenu] = useState(false)

    const customerLatestSubscriptionState: GetCustomerLatestSubscriptionState = useSelector(
        (state: GlobalAppState) => state.getCustomerLatestSubscription
    )

    const userHasSubscription = !!customerLatestSubscriptionState.subscription.length

    const showStepper = pathname !== `/${routes.MENU}` && subscriptionRoutes.includes(pathname)
    const isMobile = useCheckMobile(992)

    const onLinkClick = (route: string) => {
        setOpenMobileMenu(false)
        push(`/${route}`)
    }

    if (isLoading) {
        return <div />
    }
    if (isAuthenticated) {
        handleAuth = () => logout({ returnTo: process.env.NEXT_PUBLIC_LOGOUT_URI })
        authLabel = translate("mealhaus.signOut")
    }
    else {
        handleAuth = () => login({ appState: { returnTo: { pathname, query, asPath } } })
        authLabel = translate("mealhaus.signIn")

    }

    if (showStepper) {
       return ( <Fragment>
            <div className="border-bottom bg-white">
                <nav className="navbar container justify-content-center align-items-baseline">
                    <Link href={`/`}>
                        <a className="text-decoration-none text-dark navbar-brand">
                            <img className="logo" src="https://mealhaus.s3.eu-central-1.amazonaws.com/static/logo2.png" alt="LOGO" />
                        </a>
                    </Link>
                    <div className={classNames("d-flex align-items-center justify-content-center flex-grow-1 mt-2",{"m-negative-130": !isMobile})}>
                    <Stepper/>
                    </div>
                </nav>
            </div>
            <style jsx>
                {`
                .m-negative-130{
                    margin-left: -130px;
                }
                .logo {
                    max-width: 120px;
                    vertical-align: middle;
                    display: inline-block;
                    }
                `}
            </style>
        </Fragment>)
    }

    return (
        <Fragment>
            <div className="border-bottom bg-white">
                <nav className="navbar container align-items-baseline flex-nowrap">
                    <Link href={`/`}>
                        <a className="text-decoration-none text-dark navbar-brand">
                            <img className="logo" src="https://mealhaus.s3.eu-central-1.amazonaws.com/static/logo2.png" alt="LOGO" />
                        </a>
                    </Link>
                    {
                        userHasSubscription && (
                            <ul className="navbar-nav flex-row d-none d-md-flex">
                                <li className="nav-item ">
                                    <Link href={`/${routes.MENU}`}>
                                        <a className="text-decoration-none nav-link text-dark text-small font-weight-light ml-3">Week plan</a>
                                    </Link>
                                </li>
                            </ul>
                        )
                    }

                    <span className="d-none d-md-block ml-auto">
                        <button className="btn btn-outline-primary" onClick={handleAuth}>{authLabel}</button>
                    </span>
                    <button className="btn text-xxlarge d-md-none" onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                        <BsList />
                    </button>
                </nav>
            </div>

            <MobileModal isOpen={openMobileMenu} onClose={() => setOpenMobileMenu(!openMobileMenu)}>
                <div className="text-center px-4">
                    <button className="btn btn-outline-primary btn-block" onClick={handleAuth}>{authLabel}</button>
                </div>
                {
                   userHasSubscription && (
                        <div className="p-4">
                            <div className="pointer" onClick={() => onLinkClick(routes.MENU)}><span className="text-xlarge"><BsCardList className="mr-3" /></span>Week plan</div>
                        </div>
                    )
                }
            </MobileModal>
            <style jsx>
                {`
                .logo {
                max-width: 120px;
                vertical-align: middle;
                display: inline-block;
                }
                `}
            </style>
        </Fragment>
    )

}
