import * as Actions from './actions';
import { GetCustomerLatestSubscriptionState, getCustomerLatestSubscriptionInitialState } from './state';

export const getCustomerLatestSubscriptionReducer = (
    state: GetCustomerLatestSubscriptionState = getCustomerLatestSubscriptionInitialState,
    action: Actions.GetCustomerLatestSubscriptionActions
): GetCustomerLatestSubscriptionState => {
    switch (action.type) {
        case Actions.GET_CUSTOMER_LATEST_SUBSCRIPTION_TYPE:
            return { ...state, isLoading: true };
        case Actions.GET_CUSTOMER_LATEST_SUBSCRIPTION_SUCCESS_TYPE:
            return { ...state, isLoading: false, subscription: action.payload, isLoaded:true };
        case Actions.GET_CUSTOMER_LATEST_SUBSCRIPTION_FAILURE_TYPE:
            return { ...state, isLoading: false, error: action.payload, isLoaded:true };
        default:
            return state;
    }
};
