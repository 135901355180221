import * as GetOrdersActions from './actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import { orderService } from '../order.service';
import { Order } from '../order.interface';
import { OrderResponse } from '../order-response.interface';

export function* getOrdersSaga({ payload }: GetOrdersActions.GetOrdersAction) {
    try {
        const orders: OrderResponse[] = yield call(orderService.getOrders);
        yield put(GetOrdersActions.getOrdersSuccess(orders));
    } catch (error) {
        yield put(GetOrdersActions.getOrdersFailure(error));
    }
}

export const GetOrdersSagas = [takeLatest(GetOrdersActions.GET_ORDERS_TYPE, getOrdersSaga)];
