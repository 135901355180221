import { Order } from "./order.interface";
import { OrderResponse } from "./order-response.interface";
import { fetchClient } from "@goodfood/lib";
import { getEndpoint } from "@goodfood/utils";

async function createOrder(payload): Promise<Order> {
  return (await fetchClient.postData<Order>(getEndpoint(`/order`),payload))!;
}

async function getOrder(orderId: string): Promise<OrderResponse> {
  return (await fetchClient.getData<OrderResponse>(getEndpoint(`/order/${orderId}`)))!;
}

async function getOrders(): Promise<OrderResponse[]> {
  return (await fetchClient.getData<OrderResponse[]>(getEndpoint(`/order`)))!;
}

export const orderService = {
  createOrder,
  getOrder,
  getOrders
};
