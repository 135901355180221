import { createAction } from "@goodfood/utils";
import { CreateMealPlanSubscriptionSubscriptionDto } from "@mealhaus/meal-plan-subscription";

export type GetCustomerLatestSubscriptionActions =
  | GetCustomerLatestSubscriptionAction
  | GetCustomerLatestSubscriptionSuccessAction
  | GetCustomerLatestSubscriptionFailureAction;

export const GET_CUSTOMER_LATEST_SUBSCRIPTION_TYPE = "[GOOD FOOD SHOP] Get Customer latest subscription";
export type GetCustomerLatestSubscriptionAction = ReturnType<typeof getCustomerLatestSubscription>;
export const getCustomerLatestSubscription = () =>
  createAction(GET_CUSTOMER_LATEST_SUBSCRIPTION_TYPE);

export const GET_CUSTOMER_LATEST_SUBSCRIPTION_SUCCESS_TYPE =
  "[GOOD FOOD SHOP] Get Customer latest subscription success";
export type GetCustomerLatestSubscriptionSuccessAction = ReturnType<typeof getCustomerLatestSubscriptionSuccess>;
export const getCustomerLatestSubscriptionSuccess = (subscription: CreateMealPlanSubscriptionSubscriptionDto[]) =>
  createAction(GET_CUSTOMER_LATEST_SUBSCRIPTION_SUCCESS_TYPE, subscription);

export const GET_CUSTOMER_LATEST_SUBSCRIPTION_FAILURE_TYPE =
  "[GOOD FOOD SHOP] Get Customer latest subscription failure";
export type GetCustomerLatestSubscriptionFailureAction = ReturnType<typeof getCustomerLatestSubscriptionFailure>;
export const getCustomerLatestSubscriptionFailure = (error: any) =>
  createAction(GET_CUSTOMER_LATEST_SUBSCRIPTION_FAILURE_TYPE, error);
