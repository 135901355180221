interface config {
    audience: string
    scope: string
    redirectUri: string
    clientId: string
    authDomain: string
}
export function getconfig(): config {
    return {
        audience: process.env.NEXT_PUBLIC_AUDIENCE,
        scope: '',
        redirectUri: process.env.NEXT_PUBLIC_REDIRECT_URI,
        clientId: process.env.NEXT_PUBLIC_CLIENT_ID,
        authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN

    }
}
