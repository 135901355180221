import { Order } from "../order.interface";

export interface CreateOrderState {
  isLoading: boolean;
  error: any;
  order: Order[];
}

export const createOrderInitialState: CreateOrderState = {
  isLoading: false,
  error: null,
  order: []
};
