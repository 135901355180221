export interface TypedAction<T, P> {
    readonly type: T;
    readonly payload: P;
}

export function createAction<T extends string, P>(type: T, payload: P): TypedAction<T, P>;
export function createAction<T extends string>(type: T): TypedAction<T, undefined>;
export function createAction<T extends string, P>(type: T, payload?: P): TypedAction<T, P | undefined> {
    return { type, payload };
}
