import * as Actions from './actions';
import { GetOrderState, getOrderInitialState } from './state';

export const getOrderReducer = (
    state: GetOrderState = getOrderInitialState,
    action: Actions.GetOrderActions
): GetOrderState => {
    switch (action.type) {
        case Actions.GET_ORDER_TYPE:
            return { ...state, isLoading: true };
        case Actions.GET_ORDER_SUCCESS_TYPE:
            return { ...state, isLoading: false, order: action.payload };
        case Actions.GET_ORDER_FAILURE_TYPE:
            return { ...state, isLoading: false, error: action.payload };
        default:
            return state;
    }
};
