import { OrderResponse } from "../order-response.interface";
export interface GetOrderState {
    isLoading: boolean;
    error: any;
    order: OrderResponse
}

export const getOrderInitialState: GetOrderState = {
    isLoading: false,
    error: null,
    order: null
};

